@font-face {
    font-family: 'roman55';
    src: url('./Fonts/univers-lt-55-roman.ttf');
}

html, body {
  width: 100%;
  height: 100%;
}

.navbar {
  font-size: 18px;
}

.navbar a {
  color: #212529 !important;

}

.navbar a:hover {
  color: grey !important;
  /*border: 1px solid;*/
  /*box-shadow: 5px 5px;*/
  opacity: 0.8;
}

.thank-jumbo {
  background-color: #2C3753;
  background-image: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),
  url("./Images/exp/5f6a544c6031a.jpg");
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  height: 250px;
}

.negative-margin {
  margin-top: -100px;
  margin-bottom: -150px;
}

.contact-jumbo {
  background: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),url("./Images/exp/5f6b4cdca1c5d.jpg") no-repeat center center;
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-size: cover;
  height: 250px;
  color: white;
}

.quote-jumbo {
  background-image: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),
  url("./Images/exp/5f68ea80d0110.jpg");
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
}

.logistics-jumbo {
  background-image: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),
  url("./Images/img_download_0.jpg");
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-size: cover;
  background-repeat: no-repeat;
  height:250px;
  color: white;
}

.industry-jumbo {
  background-image: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),
  url("./Images/exp/5f68d635181ac.jpg");
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  height: 250px;
}

.location-jumbo {
  background-image: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),
  url("./Images/exp/5f68e8a5dbfb6.jpg");
  -webkit-filter: grayscale(50%);
  filter: grayscale(50%);
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  height: 250px;
}


.vertical-center {
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center;
}


.container {
  flex: 1;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.container-fluid{
overflow: hidden;
}

.row {
  padding-top: 1rem;
  padding-bottom: 1rem;
}


@media only screen and (max-width: 1099px) {
  .footer {
    color: white;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(./Images/shutterstock_1457551232-reduced-60.jpg);
  }
}

@media only screen and (min-width: 1100px) {
  .footer {
    color: white;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(./Images/shutterstock_1457551232big-reduced-60.jpg);
    /*background-image: linear-gradient(to right, rgba(38, 58, 74, 0.52), rgba(70, 119, 159, 0.73)),
    url(./Images/shutterstock_1457551232big.jpg);*/
  }
}

.footer .row {
  padding-bottom: 0;
}

.main img {
  -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
  filter: blur(1px);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centered-left {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
}
.centered-left  h1 {
  color: white;
  font-size: 4rem;
}

.centered-left  p {
  color: white;
  font-size: 1.3rem;
  font-family: serif;
}


.centered h1 {
  color: white;
  font-size: 3rem;
}

.centered p {
  color: white;
  font-size: 1.3rem;
}

.grey-background {
  background-color: #F9F9F9;
}

.green-blue-background {
  background-color: #F9FDFE;
}

.habt {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 15%;
  padding-right: 15%;
}

.habt h1 {
  font-size: 3em;
}

.habt p {
  line-height: 2;
  font-weight: 400;
}

@media only screen and (min-width: 1004px) {
  .habt img {
    background-size: cover;
  }
}

@media only screen and (max-width: 767px) {
  .quote {
    width: 100%;
  }

  .hom-car {
    width: 100%;
  }

  .locnpar {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

@media only screen and (min-width: 768px) {
  .quote {
    padding-left: 20%;
    padding-right: 20%;
  }

  .hom-car {
    padding-left: 10%;
    padding-right: 10%;
  }

  .locnpar {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
}

.success h5 {
  line-height: 2rem;
}

.locnpar h1 {
  font-size: 3em;
}

.locnpar p {
  line-height: 2rem;
  font-family: 'roman55','Open Sans';
}

.locnpar h6 {
  font-weight: 400;
  line-height: 2rem;
  font-family: 'roman55','Open Sans';
}

.locnpar li {
  padding-top: 3%;
  line-height: 2rem;
  font-family: 'roman55','Open Sans';
}

.locnpar strong {
  font-size: 125%;
}

.link {
  color: white;
  background-color: transparent;
  text-decoration: none;
  text-transform: none;
}

.link:hover {
  color: grey;
  background-color: transparent;
  text-decoration: none;
}

.link1 {
  color: black;
  background-color: transparent;
  text-decoration: none;
  text-transform: none;
}

.link1:hover {
  color: grey;
  background-color: transparent;
  text-decoration: none;
}

.form-group {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;
}

.wide-pic {
  min-width: 100%;
  position: relative;
}

.filtered {
  min-width: 100%;
  background: #2C5C87;
  position: relative;
}
.filtered img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

@media(max-width: 575px) {
  .logo-row {
    height:900px;
  }
}

@media(min-width: 576px) and (max-width: 767px) {
  .logo-row {
    height:450px;
  }
}


.logo {
  max-width: 80%;
  max-height: 80%;
  min-width: 80%;
  min-height: 80%;
}

.container-div {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.logo-wrapper {
  text-align: center;
}

.about_person {
  max-width: 100%;
  max-height: 100%;
  padding-bottom: 0.5rem;
}

.solutions_logo {
  width: 60%;
}

.white-background {
  background-color: #ffffff;
}

#multimodal { display: none; }

@media(min-width: 992px) {
  #multimodal { display: block; }
}

.solutions_abt {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 100%;
}

.solutions_abt h1 {
  font-size: 3em;
}

.solutions_abt p {
  line-height: 2;
  font-weight: 400;
}
